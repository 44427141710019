// (Copyright) Confluent, Inc.
// @flow

import * as React from 'react';
import styled from 'styled-components';
import { LegacyTooltip, Icon } from '@confluent/core';

import {
  pluginTypeTooltip,
  enterpriseSupportTooltip,
  verificationTooltip,
  licenseTooltip,
  cloudTooltip,
} from '../../constants/tooltips';
import {
  componentLabels as componentTypes,
  enterpriseSupportLabels as enterpriseSupportTypes,
  verificationLabels as verificationTypes,
  licenseLabels as licenseTypes,
} from '../../constants/labels';
import CheckboxGroup from '../CheckboxGroup';
import { INITIAL_FILTERS, type Filters as FiltersT } from '../../models/plugin';

const pluginOptions = Object.keys(componentTypes).map((type) => ({
  label: componentTypes[type],
  value: type,
}));

const enterpriseOptions = Object.keys(enterpriseSupportTypes).map((type) => ({
  label: enterpriseSupportTypes[type],
  value: type,
}));

const verificationOptions = Object.keys(verificationTypes).map((type) => ({
  label: verificationTypes[type],
  value: type,
}));

const licenseOptions = Object.keys(licenseTypes).map((type) => ({
  label: licenseTypes[type],
  value: type,
}));

const cloudOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

type Props = {
  onChange: (FiltersT) => void,
  value: FiltersT,
};

const Filters = ({ onChange, value }: Props) => {
  const values = value || INITIAL_FILTERS;
  const handleChange = (key) => (nextValues) => {
    const updatedValues: FiltersT = { ...values };
    updatedValues[key] = nextValues;
    onChange(updatedValues);
  };

  return (
    <>
      <Header>Filters</Header>
      <Filter
        onChange={handleChange('component_types')}
        options={pluginOptions}
        values={values.component_types}
      >
        Plugin type
        <LegacyTooltip inline={true} tipBody={pluginTypeTooltip}>
          <Icon name="info" size={12} />
        </LegacyTooltip>
      </Filter>

      <Filter
        onChange={handleChange('enterprise_support')}
        options={enterpriseOptions}
        values={values.enterprise_support}
      >
        Enterprise support
        <LegacyTooltip inline={true} tipBody={enterpriseSupportTooltip}>
          <Icon name="info" size={12} />
        </LegacyTooltip>
      </Filter>

      <Filter
        onChange={handleChange('verification')}
        options={verificationOptions}
        values={values.verification}
      >
        Verification
        <LegacyTooltip inline={true} tipBody={verificationTooltip}>
          <Icon name="info" size={12} />
        </LegacyTooltip>
      </Filter>

      <Filter
        onChange={handleChange('license_type')}
        options={licenseOptions}
        values={values.license_type}
      >
        License
        <LegacyTooltip inline={true} tipBody={licenseTooltip}>
          <Icon name="info" size={12} />
        </LegacyTooltip>
      </Filter>

      <Filter
        onChange={handleChange('cloud_availability')}
        options={cloudOptions}
        values={values.cloud_availability}
      >
        Available in Confluent Cloud
        <LegacyTooltip inline={true} tipBody={cloudTooltip}>
          <Icon name="info" size={12} />
        </LegacyTooltip>
      </Filter>
    </>
  );
};

export default Filters;

export { INITIAL_FILTERS } from '../../models/plugin';

const Filter = ({ children, options, values, onChange }) => (
  <Wrapper>
    <Title>{children}</Title>
    <CheckboxGroup onChange={onChange} options={options} values={values} />
  </Wrapper>
);

const Wrapper = styled.div`
  margin-bottom: 48px;
`;

const Header = styled.div`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
  color: var(--DIM_DARKER);
  font-weight: bold;
`;

const Title = styled.div`
  font-size: 18px;
  line-height: 36px;
  border-bottom: 2px solid var(--DIM_LIGHTER);
  margin-bottom: 16px;

  svg {
    vertical-align: middle;
    margin-left: 4px;
  }
`;
